<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
      :mark="mark"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`产品功能`"
      :en_title="`Product Function`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist1" style="margin-left: 15%"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist2" style="margin-left: 15%"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
      :title="`产品特色`"
      :en_title="`Product Feature`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist3" style="margin-left: 15%"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`应用场景`"
      :en_title="`Application Scenarios`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist4" style="margin-left: 15%"></Titlestrlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
  },
  data() {
    return {
      title: '蚂蚁溯源链服务',
      body:
        '大规模商用的溯源服务平台，利用区块链和物联网技术，解决了溯源信息的真实性问题。'
        + '通过营销能力，驱动业务快速增长。拥有跨境商品、原产地真品、产业溯源、食品监管等溯源方案，适用于全领域溯源场景。',
      image: require('@/assets/image/chain/alliance/antsc/antsc.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/alliance/antsc/1.jpg'),
        require('@/assets/image/chain/alliance/antsc/2.jpg'),
        require('@/assets/image/chain/alliance/antsc/3.jpg'),
        require('@/assets/image/chain/alliance/antsc/4.jpg'),
      ],
      mark: '1',
      datalist1: [
        {
          title: '> 溯源服务管理',
          content:
            '提供从品牌到商品的精细化溯源管理服务，包括品牌商管理、商品管理、溯源管理。',
        },
        {
          title: '> 多标识管理',
          content:
            '提供多标识（如二维码、RFID、NFC芯片）的全生命周期管理，包括规则配置、标识生成、查询标识、下载标识。',
        },
        {
          title: '> 客群洞察',
          content:
            '提供基于扫码数据的客群洞察服务，包括客群分布的热力图、扫码统计。',
        },
      ],
      datalist2: [
        {
          title: '> 大规模商用',
          content:
            '平台拥有全球超过 30 亿的上链商品量，覆盖超过 100 个国家和 14 万的各品类商品。',
        },
        {
          title: '> 全领域覆盖',
          content:
            '平台覆盖各个领域的溯源，包括但不限于食品药品、农产品、酒、茶、工艺品、陶瓷、钻石、美妆等。',
        },
        {
          title: '> 全域营销能力',
          content:
            '拉通阿里巴巴天猫淘宝体系，实现全域营销，通过直播带货、流量引入等手段帮助建立品牌，提升销量。',
        },
        {
          title: '> 支付宝一键查验',
          content: '通过支付宝的扫一扫入口，直接查验商品的溯源信息，可靠易用。',
        },
      ],
      datalist3: [
        {
          title: '> 双端服务模式',
          content:
            '一方面为商家提供溯源商品信息的个性化定制服务，另一方面并为其C端消费者提供支付宝小程序供溯源验证，实现溯源便捷的同时，助力商家精准获客。',
        },
        {
          title: '> 一键式极速接入',
          content:
            '提供简单易用、多样化的接入方式，无需IT能力也能快速实现区块链溯源赋能。',
        },
        {
          title: '> 支付宝扫一扫入口',
          content:
            '通过支付宝的扫一扫可直接查验溯源信息结果，无需额外入口，有效防止假冒钓鱼网站。',
        },
        {
          title: '> 高扩展高安全性',
          content:
            '平台提供安全完整的API接口，支持无缝对接如ERP、CRM、WMS等自有系统。',
        },
      ],
      datalist4: [
        {
          title: '> 通用溯源',
          content:
            '基于蚂蚁链溯源服务平台的基础能力，为泛行业的商品提供区块链溯源服务，如跨境商品、茶叶、酒、家电等。',
        },
        {
          title: '> 跨境溯源',
          content:
            '通过精准追溯的能力，对跨境商品的生产、装运、检测、入关清关等数据进行全生命周期的溯源，并赋予唯一的“身份证”，让消费者真正买的放心。',
        },
        {
          title: '> 原产地溯源',
          content:
            '针对具有地标特色的原产地商品，提供专属的溯源营销服务。在溯源的基础上叠加营销能力，如线上直播带货、线下活动策划等。',
        },
        {
          title: '> 监管溯源',
          content:
            '针对监管机构提供的溯源管理能力，监管机构可针对链上企业的溯源信息进行监管，提高监管效率。',
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
