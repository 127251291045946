<template>
  <div class="banner">
    <!-- <el-image :src="image" class="banner" fit="cover"/> -->
    <!-- after compile in webpack, svg will be displayed as webp format -->
    <div
      v-if="
        image.substring(image.length - 4) === 'webp' ||
        image.substring(image.length - 3) === 'jpg'
      "
      class="text-box-svg"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    >
      <div class="banner-text">
        <div class="text-top">{{ title }}</div>
        <div class="text">
          {{ body }}
        </div>
        <div class="flexcenterc buttondiv" style="justify-content: left;flex-direction: row">
          <el-button id="detail-button" class="flexcenterc" @click="skip">了解详情</el-button>
          <el-button id="buy-button" class="flexcenterc" @click="buy">购买</el-button>
        </div>
      </div>
    </div>
    <div v-else class="text-box-img">
      <div class="banner-text">
        <div class="text-top">{{ title }}</div>
        <div class="text">
          {{ body }}
        </div>
        <div class="flexcenterc buttondiv" style="justify-content: left;flex-direction: row">
          <el-button id="detail-button" class="flexcenterc" @click="skip">了解详情</el-button>
          <el-button id="buy-button" class="flexcenterc" @click="buy">购买</el-button>
        </div>
      </div>
      <div class="svg-image">
        <img :src="image" class="svg-normal"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['image', 'title', 'body', 'showDetailBtn', 'showBuyBtn', 'mark'],
  data() {
    return {};
  },
  methods: {
    buy() {
      this.$router
        .push({
          name: 'Form',
          query: {
            id: this.$route.matched.slice(-1)[0].props.default.formId,
            groupName: this.$route.matched.slice(-1)[0].props.default.label,
          },
        })
        .catch((e) => e);
    },
    skip() {
      const bannerHeight = document.getElementsByClassName('banner')[0];
      let scrolly = bannerHeight.clientHeight;
      scrolly = bannerHeight.offsetHeight;
      window.scroll(0, scrolly);
    },
  },
  created() {
    console.log(this.image);
  },
};
</script>

<style scoped>
.banner {
  height: 36vw;
}
.text-box-svg {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-size: 100%,100%;
  background-repeat: no-repeat;
  height: 100%;
}

.illustration {
  position: absolute;
  width: 155%;
  margin-top: 15%;
  height: 155%;
  fill: #ffffff;
  opacity: 0.1;
  overflow: hidden;
}
.truelogo{
  width: 68%;
  height: 68%;
  fill: #ffffff;
  position: absolute;
}
.text-top {
  width: 40vw;
  min-width: 400px;
  color: #ffffff;
  font-size: 2vw;
}
.text {
  margin-top:4vw;
  position: absolute;
  max-width: 90%;
  color: #ffffff;
  font-size: 1vw;
  line-height: 1.8vw;
}
.svg-image{
  width: 49%;
  height: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttondiv{
  position: absolute;
  bottom: 0;
}
.banner-text {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
  margin-left: 10%;
  height: 50%;
  width: 40%;
  position: relative;
}

.text-box-img {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  height: 100%;
  background: #2285ff;
}
#detail-button {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  height: 2.5vw;
  width: 8vw;
  font-size: 0.8vw;
}

.detail-button:hover {
  background-color: white;
  color: #409eff;
}

#buy-button {
  color: #2086f9;
  border-radius: 0;
  border-color: white;
  height: 2.5vw;
  width: 8vw;
  font-size: 0.8vw;
  margin-left: 1vw;
}
.svg-normal {
  width: 80%;
  height: 80%;
}
.flexcenterc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
